<template>
	<section class="video_box">
		<div class="for_desktop" v-if="!props.isMobile">
			<video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="3840" height="1520">
				<source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/christmas_desktop.mp4" type="video/mp4">
			</video>
		</div>
		<div v-else class="for_mobile">
			<video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="1380" height="1920">
				<source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/christmas_mobile.mp4" type="video/mp4">
			</video>
		</div>

		<div class="upper_text">
			<span>Celebrate love and sparkle</span>
			<h3 class="font-active mb-3">Flat 40% Off Sitewide</h3>
			<!-- <p>
				Shop now to get it in time for Christmas!
			</p> -->
			<div class="anker_flex">
				<router-link :to="localePath('/engagement-rings')">SHOP ENGAGEMENT RINGS</router-link>
				<router-link :to="localePath('/fine-jewelry')">SHOP FINE JEWELRY</router-link>
			</div>
		</div>

		<p class="terms-and-condition" @click="openBFModal">
			<!-- <span class="for_desktop">*terms & Conditions Applied</span> -->
			<span >*T&C APPLIED</span>
		</p>

		<CommonCustomModal v-model="flagShowModal" :size="'lg'" :no_padd="true">
			<ModalTermsAndConditionsBF />
        </CommonCustomModal>
		
		
	</section>
</template>

<script setup>
const localePath = useLocalePath()
const props = defineProps(['isMobile'])

const flagShowModal = ref(false);
	const openBFModal = () => {
        flagShowModal.value = true;
    };

</script>

<style scoped>
.video_box{
	position: relative;
}
.video_box:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(196, 196, 196, 0) 60%, #c4c4c4 100%);
}
video{
	max-width: 100%;
	height: auto;
}
.upper_text {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
	width: 100%;
}

.upper_text span {
    color: #312b2b;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
}

.upper_text h3 {
    font-size: 60px;
    color: #312b2b;
}

.upper_text h3::after {
	content: '*'; 
	font-size: 20px; 
	color: #312b2b; 
	margin-left: 4px; 
	vertical-align: top; 
}



.upper_text p {
    color: #312b2b;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.anker_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.anker_flex a {
	letter-spacing: 1px;
    font-size: 14px;
    color: #000;
    border: 1px solid #000;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 4px;
    margin: 0 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
}


.terms-and-condition{
	font-size: 10px;
	position: absolute;
	bottom: 10px;
	right: 15px;
	text-transform: uppercase;
	margin: 0;
	cursor: pointer;
	z-index: 2;
	color: #606060;
	text-decoration: underline;
}
@media (max-width:1024px) {
	.upper_text h3 {
		font-size: 40px;
	}
	.upper_text span, .upper_text p {
		font-size: 16px;
	}
}
@media (max-width:767px) {
	.upper_text h3::after{
		color: #fff;
	}
	.video_box:before {
		background-image: linear-gradient(to bottom, rgba(196, 196, 196, 0) 60%, #000000ad 100%);
		height: 99%;
	}
	.upper_text h3, .upper_text span, .upper_text p {
		color: #fff;
	}
	.anker_flex a {
		color: #fff;
		border: 1px solid #fff;
	}
	.video_box {
		position: relative;
		/* min-height: 60vh; */
	}
	.upper_text h3 {
		font-size: 32px;
	}
	.upper_text span, .upper_text p {
		font-size: 14px;
	}
	.upper_text{
		width: 90%;
	}
	.anker_flex {
		flex-wrap: wrap;
	}
	.anker_flex a {
		width: 100%;
		margin-bottom: 10px;
		display: flex;
	}
}

</style>