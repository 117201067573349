<template>
    <div class="video_box">
        <div class="for_desktop" v-if="!props.isMobile">
            <video @contextmenu.prevent="" controlsList="nodownload" loop muted autoplay playsinline width="3840" height="1520">
                <source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/home-page-banner-2.mp4" type="video/mp4">
            </video>
        </div>
        <div v-else class="mb-3 for_mobile">
            <video @contextmenu.prevent="" controlsList="nodownload" loop muted autoplay playsinline width="1380" height="1920">
                <source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/home-page-banner-mob-2.mp4" type="video/mp4">
            </video>
        </div>
		<div class="text_box">
			<h4 class="mb-2 font-active">Keep It Real. Keep It Friendly.</h4>
			<p class="mb-4">Manifest a friendlier tomorrow with <br> Earth-kind lab grown diamond {{ $t('jewelry') }}</p>
			<div class="banner_btn">
                <router-link class="anker radious_four" :to="localePath('/engagement-rings')">SHOP ENGAGEMENT RINGS</router-link>
                <router-link class="anker radious_four" :to="localePath('/fine-jewelry')">SHOP FINE {{ $t('jewelry') }}</router-link>
            </div>
		</div>
    </div>
</template>
<script setup>
	const localePath = useLocalePath()
	const props = defineProps(['isMobile'])
</script>
  
<style scoped>
	.video_box{
		position: relative;
	}
  	video{
		max-width: 100%;
		height: auto;
		/* clip-path: inset(0); */
		clip-path: inset(1px 1px) !important;
	}
	.text_box {
		position: absolute;
		bottom: 90px;
		left: 90px;
		text-align: left;
		color: #ffffff;
	}

	.text_box h4 {
		font-size: 50px;
	}

	.text_box p {
		font-size: 18px;
		letter-spacing: 3px;
		text-align: left;
		text-transform: uppercase;
	}

	.banner_btn {
		display: flex;
		justify-content: flex-start;
	
	}

	.banner_btn a {
		padding: 7px 20px;
		margin-right: 3%;
		color: #ffffff;
		border: 1px solid #ffffff;
		font-size: 14px;
		text-transform: uppercase;
	}

	@media (max-width:1250px) {
		.text_box h4 {
			font-size: 42px;
		}

		.text_box p {
			font-size: 16px;
		}
	}
	
	@media (max-width:767px) {
		.video_box {
			position: relative;
			min-height: 60vh;
		}
		.text_box {
			bottom: 0;
			left: 50%;
			text-align: center;
			transform: translate(-50%, -50%);
			width: 96%;
		}
		.text_box h4 {
			font-size: 24px;
			text-align: center;
		}
		.text_box p {
			font-size: 12px;
			letter-spacing: 2px;
			text-align: center;
			margin: 10px !important;
		}
		.banner_btn {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.banner_btn a {
			margin: 5px;
			padding: 7px 20px;
			max-width: 300px;
			width: 100%;
		}
	}
	@media (max-width:512px) {
		.text_box {
			bottom: 0;
			left: 50%;
			text-align: center;
			transform: translate(-50%, -20%);
			width: 96%;
		}
	}
</style>