<template>
    <section class="ur_own py-4">
        <div class="main_row">
            <div class="left_row">
                <div class="cyo_text radious_eight">
                    <div>
                        <h1 class="mb-0">Create Your Own </h1>
                        <p class="mb-0">Craft unique engagement rings, earrings and pendants based on your liking and preferences. <br> 
                            Choose from an array of beautiful settings and select your favourite diamond shape to get started.</p>
                    </div>
                </div>
                <div class="cyo_row">                  
                    <div class="cyo_box for_mobile">
                        <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})" class="radious_eight">
                            <picture>
                                <source width="690" height="310" type="image/webp" srcset="~/assets/home/cyo/india/ring_mob.webp">
                                <img loading="lazy" width="690" height="310" type="image/jpeg" src="~/assets/home/cyo/india/ring_mob.jpg" alt="Create Your Own Engagement Rings Picture">
                            </picture>
                            <span>Engagement Rings</span>
                        </NuxtLink>
                    </div>

                    <div class="cyo_box cyo_box_mod_one">
                        <NuxtLink :to="localePath({ name:'cyo-setting-list-pendant'})" class="radious_eight">
                            <picture>
                                <source width="900" height="860" type="image/webp" srcset="~/assets/home/cyo/india/pendant.webp">
                                <source width="900" height="860" type="image/jpeg" srcset="~/assets/home/cyo/india/pendant.jpg"> 
                                <img loading="lazy" width="900" height="860" type="image/jpeg" src="~/assets/home/cyo/pendant.jpg" alt="Create Your Own Pendants Picture">
                            </picture>
                            <span>Pendants</span>
                        </NuxtLink>
                    </div>

                    <div class="cyo_box cyo_box_mod_two">
                        <NuxtLink :to="localePath({ name:'cyo-setting-list-earring'})" class="radious_eight">
                            <picture>
                                <source width="900" height="860" type="image/webp"  srcset="~/assets/home/cyo/india/earring.webp">
                                <source width="900" height="860" type="image/jpeg"  srcset="~/assets/home/cyo/india/earring.jpg">
                                <img loading="lazy" width="900" height="860" type="image/jpeg" src="~/assets/home/cyo/earring.jpg" alt="Create Your Own Earrings Picture">
                            </picture>
                            <span>Earrings</span>
                        </NuxtLink>    
                    </div>   
                </div>
            </div>

            <div class="right_row for_desktop">
                <div class="cyo_box ">
                    <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})" class="radious_eight">
                        <picture>
                            <source width="880" height="1454" type="image/webp" srcset="~/assets/home/cyo/india/ring.webp">
                            <img loading="lazy" width="880" height="1454" type="image/jpeg" src="~/assets/home/cyo/india/ring.jpg" alt="Create Your Own Engagement Rings Mobile Picture">
                        </picture>
                        <span>Engagement Rings</span>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const localePath = useLocalePath()
    const props = defineProps(['locale'])
</script>

<style scoped>

.main_row{
    display: flex;
}
img{
    /* image-rendering: pixelated; */
}
.left_row{
    display: flex;
    flex: 65%;
    flex-direction: column;
    padding-right: 5px;
}
.right_row{
    flex: 35%;
    padding-left: 5px;
}
.cyo_row{
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 7px;
}
.cyo_text {
    background: #405955;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 6% 15%;
    margin-bottom: 7px;
}
.cyo_text h1 {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 10px;
}
.cyo_text p {
    font-size: 16px;
    font-weight: 400;
    padding-top: 3%;
    color: #eaeaea;
}
.cyo_box {
    position: relative;
    flex: 1;
}
.cyo_box:first-child {
    margin-right: 8px;
}
.cyo_box_mod{
    display: none;
}
.cyo_box:last-child {
    margin-left: 8px;
}
.cyo_box a {
    display: block;
    color: #000;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.cyo_box a img:hover{
    transform: scale(1.1);
    transition: 0.8s;
}
.cyo_box a img{
    transition: 0.8s;
}
.cyo_box span {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    display: block;
    font-size: 18px;
    font-weight: 400;
    width: calc(100% + 2px);
    text-align: center;
    background: rgba(255, 255, 255, 0.68);
    color: #000;
    padding: 7px;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.cyo_box:hover span {
    color: #000;
}
@media(max-width: 1150px){
    .left_row{
        width: 64%;
    }
    .right_row{
        width: 36%;
    }
    .cyo_box span {
        font-size: 16px;
        letter-spacing: 5px;
    }
    .cyo_text h1 {
        font-size: 24px;
        letter-spacing: 3px;
    }
    .cyo_text  br{
        display: none;
    }
    .cyo_text{
        padding: 6%;
    }
}
@media(max-width: 992px){
    .left_row{
        width: 60%;
    }
    .right_row{
        width: 40%;
    }
    .cyo_box span{
        font-size: 14px;
        letter-spacing: 2px;
    }
    .cyo_text{
        padding: 0 5%;
    }
    .cyo_text p{
        font-size: 14px;
        padding-top: 0;
    }
    .cyo_text h1{
        font-size: 22px;
    }
}
@media(max-width: 767px){
    .cyo_box.cyo_box_mod_two {
        padding-left: 10px;
    }

    .cyo_box.cyo_box_mod_one {
        padding-right: 10px;
    }
    .cyo_box {
        margin-bottom: 20px;
    }
    
    .main_row{
        flex-wrap: wrap;
    }
    .left_row{
        flex-wrap: wrap;
        flex: auto;
    }
    .cyo_row{
        flex-wrap: wrap;
    }
    .cyo_box{
        flex: auto; 
        text-align: center;
    }
   
    /* .right_row{
        display: none;
    } */
  
    .cyo_text {
        padding: 7% 10%;
        /* max-width: 319px; */
        margin: 0 auto 4% auto;
    }
    .cyo_box:last-child {
        margin-left: 0;
    }
    .cyo_box:first-child {
        margin-right: 0;
    }
    .cyo_box span {
        color: #000;
        padding: 3px;
        font-size: 12px;
    }
    .cyo_text h1{
        font-size: 20px;
    }
    .cyo_text p{
        font-size: 14px;
        padding-top: 5%;
    }
    .cyo_box.for_mobile {
        width: 100%;
    }
    .cyo_box {
        width: 50%;
    }
}
@media(max-width: 512px){
    .main_row {
        flex-wrap: wrap;
       
    }
    .cyo_box_mod{
        display: block;
    }
   
}
</style>
