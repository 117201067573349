<template>
    <div>
        <div class="banner_block">
            <div class="img_left">
                <img width="1230" height="540" src="~/assets/tennis_bracelet_banner/model.png" alt="model">
            </div>
            <div class="content text-center">
      
                    <h3>Back By popular demand!</h3>
                    <p>Tennis Bracelets starting as low as $1000 <br>shipped in just 5 days!</p>
                    <NuxtLink
                        :to="localePath({ name: 'shop-bracelet', query: { 'Shipdate': '5_days', 'RefreshKey': '5_days' } })">
                        <div class="button">
                            <span>SHOP NOW</span>
                        </div>
                    </NuxtLink>
                </div>
       
            <div class="img_right text-center">
                    <picture>
                        <source media="(max-width: 992px)" width="300" height="64" type="image/webp" srcset="~/assets/tennis_bracelet_banner/bracelet.png">
                        <img width="1174" height="540" type="image/jpeg" src="~/assets/tennis_bracelet_banner/bracelet.png"
                        alt="bracelet">
                    </picture>
                <!-- <img width="1174" height="540" src="~/assets/tennis_bracelet_banner/bracelet.png" alt="modal"> -->
            </div>
        </div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>
.banner_block {
    display: flex;
    background-image: linear-gradient(to bottom, #8b1414 0%, #570909);
    width: 100%;
    align-items: center;
    justify-content: center;
}

.img_left {
    width: 32%;
}

.img_right {
    width: 28%;
}

.content {
    width: 40%;
}

.content h3 {
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-weight: 500;
    /* margin-bottom: 16px; */
}

.content p {
    font-size: 20px;
    color: #c4c4c4;
    font-weight: 400;
    /* margin-bottom: 28px; */
}
.content p br{
    display: none;
}
.button {
    /* padding: 14px 30px; */
    border: 1.5px solid #fff;
    border-radius: 4px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    max-width: 180px;
    margin: 0 auto;
    cursor: pointer;
}

.button span {
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1.5px;
}

@media (max-width: 1770px) {
    .content h3{
        font-size: 28px;
    }
    .content p {
        font-size: 18px;
    }
    .content p br{
        display: block;
    }
    .button{
        margin-bottom: 5px;
    }
}
@media (max-width: 1440px) {
    .content h3{
        font-size: 24px;
        letter-spacing: 4px;
    }
    .content p {
        font-size: 18px;
    }
    .content p br{
        display: block;
    }
    .button{
        margin-bottom: 5px;
    }
}
@media (max-width: 1200px){
    .content h3{
        font-size: 20px;
        margin-bottom: 4px;
    }
    .content p {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .content p br{
        display: block;
    }
}

@media(max-width:992px) {
    .banner_block {
        flex-direction: column-reverse;
        padding: 2% 0;
    }

    .img_left {
        display: none;
    }

    .img_right {
        width: 100%;
    }

    .content h3 {
        font-size: 24px;
        letter-spacing: 4px;
        margin: 0;
    }

    .content p {
        font-size: 16px;
        margin: 12px 0;
    }

    .button {
        margin-bottom: 20px;
        /* padding: 7px 10px; */
        /* width: 40%; */
        height: 45px;
    }

    .content {
        width: 90%;
    }

}
</style>