<template>
    <div>
        <swiper v-bind="swiperOptions" @swiper="onSwiperLoad">
            <swiper-slide>
                <div class="slide_box">
                    <NuxtLink :to="localePath({ name:'cyo-setting-list-ring'})">
                        <picture>
                            <source width="1280" height="1500" type="image/webp" srcset="~/assets/home/christmas_products/cyo.webp">
                            <img width="1280" height="1500" type="image/jpeg" src="~/assets/home/christmas_products/cyo.jpg"
                                alt="Cyo product">
                        </picture>
                        <div class="upper_text">
                            <h4 class="font-active">Create Your Own</h4>
                            <p class="mb-3">ENGAGEMENT RINGS</p>
                            <div class="btn_box">shop now</div>
                        </div>
                    </NuxtLink>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide_box">
                    <NuxtLink :to="localePath({ name: 'preset-listing', 'RefreshKey': 'all'})">
                        <picture>
                            <source width="1280" height="1500" type="image/webp" srcset="~/assets/home/christmas_products/preset.webp">
                            <img width="1280" height="1500" type="image/jpeg" src="~/assets/home/christmas_products/preset.jpg"
                                alt="Cyo product">
                        </picture>
                        <div class="upper_text">
                            <h4 class="font-active">Pre-Designed</h4>
                            <p class="mb-3">ENGAGEMENT RINGS</p>
                            <div class="btn_box">shop now</div>
                        </div>
                    </NuxtLink>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide_box">
                    <NuxtLink :to="localePath({ name:'instock_ring', 'RefreshKey': 'all'})">
                        <picture>
                            <source width="1280" height="1500" type="image/webp" srcset="~/assets/home/christmas_products/instock.webp">
                            <img width="1280" height="1500" type="image/jpeg" src="~/assets/home/christmas_products/instock.jpg"
                                alt="Cyo product">
                        </picture>
                        <div class="upper_text">
                            <h4 class="font-active">Ready To Ship</h4>
                            <p class="mb-3">ENGAGEMENT RINGS</p>
                            <div class="btn_box">shop now</div>
                        </div>
                    </NuxtLink>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const swiperOptions = {
        slidesPerGroup:1,
        
        breakpoints: {
            0: {
                slidesPerView: 1.5,
                spaceBetween:10,
                centeredSlides:true,
                centeredSlidesBounds:true,
            },
         
            768: {
                slidesPerView: 3,
                spaceBetween:0
            }
        },
    };

    const onSwiperLoad = (swiper)=>{
        swiper?.slideTo(1)
    }
</script>

<style scoped>
.slide_box a {
    display: block;
    position: relative;
}

.slide_box a .upper_text {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.upper_text h4 {
    color: #fff;
    font-size: 28px;
}

.upper_text p {
    font-size: 16px;
    color: #fff;
    letter-spacing: 2px;
}

.btn_box {
    border: 1.5px solid #fff;
    border-radius: 4px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    max-width: 250px;
}
.slide_box a:before {
    position: absolute;
    content: '';
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.65));
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

@media(max-width: 1024px){
    .upper_text h4 {
        font-size: 24px;
    }
    .btn_box{
        max-width: 160px;
    }
}
@media(max-width: 767px){
    .upper_text p {
        font-size: 14px;
    }

    .slide_box{
        border-radius: 8px;
        overflow: hidden;
    }
}
</style>